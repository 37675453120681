import { computed } from 'vue'

export const useSnackbarPosition = () => {
  const { isPlp, isPdp } = useRouteHelper()
  const headerLogoOnTop = useState(() => false)
  const plpFunctionalBarOnTop = useState(() => false)

  const { breakpoint } = useProjectBreakpoint()
  const isMobile = breakpoint.isSmallerOrEqual('md')

  const setHeaderLogoOnTop = (value: boolean) => {
    headerLogoOnTop.value = value
  }

  const setPlpFunctionalBarOnTop = (value: boolean) => {
    plpFunctionalBarOnTop.value = value
  }

  const top = computed(() => {
    if (isPlp.value && headerLogoOnTop.value) {
      if (plpFunctionalBarOnTop.value) {
        // altezza headerLogo + altezza plpfuncitonalbar + 24px di padding
        return isMobile ? '88px' : '96px'
      } else {
        // altezza plpfuncitonalbar + 24px di padding
        return isMobile ? '16px' : '24px'
      }
    } else if (isPdp.value && headerLogoOnTop.value) {
      // altezza headerLogo + 24px di padding
      return isMobile ? '76px' : '96px'
    }
    return isMobile ? '16px' : '24px'
  })

  return { top, setHeaderLogoOnTop, setPlpFunctionalBarOnTop }
}
